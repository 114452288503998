import React, { memo } from 'react';
import { useLoadYotpo } from '@services/yotpo';
import { Flex } from '@components';
import { ContentfulComponentMap } from '@components/utils';
import { useCustomer } from '@services/shopify';
import styles from './Home.module.scss';

const Home = ({ blocks }) => {
	useLoadYotpo();
	const { data: customer } = useCustomer();

	return (
		<Flex column className={styles.container}>
			<ContentfulComponentMap
				blocks={blocks}
				clientProps={{ customer }}
			/>
		</Flex>
	);
};

export default memo(Home);
